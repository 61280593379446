import React, { forwardRef, useImperativeHandle, useState } from 'react';
import { Dialog } from '@headlessui/react';
import { useForm } from 'react-hook-form';
import { useSelector, useDispatch } from 'react-redux';

import {
  Modal,
  InlineTextInput,
  InlineSelect,
  BasicButton,
  SubmitButton,
} from '../../../../components/Base';
import { submitMember, updateMember } from '../../../../actions/member';

function MemberCreate(props, ref) {
  const { register, errors, handleSubmit } = useForm();

  const [isVisible, setIsVisible] = useState(false);
  const [initialMember, setInitialMember] = useState(null);
  const [isSubmittingForm, setIsSubmittingForm] = useState(false);

  const { result: authenticatedUser } = useSelector((state) => state.authenticatedUser);
  const { result: outlets } = useSelector((state) => state.outlet);
  const dispatch = useDispatch();

  //

  useImperativeHandle(ref, () => ({
    showForm,
    showFormWithInitialData,
  }));

  const showForm = () => setIsVisible(true);
  const closeForm = () => {
    setIsVisible(false);
    setInitialMember(null);
    setIsSubmittingForm(false);
  };

  const showFormWithInitialData = (data) => {
    setInitialMember(data);
    showForm();
  };

  //

  const onSubmitForm = (data) => {
    if (isSubmittingForm) return;

    setIsSubmittingForm(true);

    if (initialMember) {
      dispatch(updateMember(initialMember.id, data))
        .then(() => closeForm())
        .catch(() => setIsSubmittingForm(false));
    } else {
      dispatch(submitMember(data))
        .then(() => closeForm())
        .catch(() => setIsSubmittingForm(false));
    }
  };

  //

  return (
    <Modal
      isVisible={isVisible}
      ModalContent={
        <div className="sm:flex sm:items-start">
          <div className="mt-3 sm:mt-0 w-full text-center sm:text-left">
            <Dialog.Title as="h3" className="text-lg leading-6 font-medium text-gray-900">
              {initialMember ? 'Ubah Member' : 'Tambah Member'}
            </Dialog.Title>
            <div className="mt-5">
              <form id="createForm" onSubmit={handleSubmit(onSubmitForm)}>
                {initialMember ? (
                  <InlineTextInput
                    editable={false}
                    title="Outlet Daftar"
                    value={initialMember.outlet_name}
                  />
                ) : (
                  <InlineSelect
                    title="Outlet Daftar"
                    inputRef={register({ required: true })}
                    name="outlet_id"
                    options={outlets.map((o) => (
                      <option key={o.id} value={o.id}>
                        {o.name}
                      </option>
                    ))}
                    defaultValue={initialMember?.outlet_id}
                    errorMessage={errors.outlet_id && 'Outlet Pendaftaran harus dipilih'}
                  />
                )}

                <InlineTextInput
                  title="Card No"
                  inputRef={register({ required: true })}
                  name="card_no"
                  defaultValue={initialMember?.card_no}
                  errorMessage={errors.card_no && 'Kolom Card No harus diisi'}
                />

                <InlineSelect
                  title="Tipe Member"
                  inputRef={register({ required: true })}
                  name="member_type"
                  options={['REGULAR', 'PRIORITAS'].map((t) => (
                    <option key={t} value={t}>
                      {t}
                    </option>
                  ))}
                  defaultValue={initialMember?.member_type}
                  errorMessage={errors.member_type && 'Kolom Tipe Member harus dipilih'}
                />

                <InlineTextInput
                  title="Nama"
                  inputRef={register({ required: true })}
                  name="name"
                  defaultValue={initialMember?.name}
                  errorMessage={errors.name && 'Kolom Nama harus diisi'}
                />

                {(!initialMember || Number(authenticatedUser?.user?.role_id) === 1) && (
                  <InlineTextInput
                    title="Phone"
                    inputRef={register({ required: true })}
                    name="phone"
                    defaultValue={initialMember?.phone}
                    errorMessage={errors.price && 'Kolom Phone harus diisi'}
                  />
                )}

                <InlineTextInput
                  title="Nama Marketing"
                  inputRef={register({ required: false })}
                  name="marketing_name"
                  defaultValue={initialMember?.marketing_name}
                  errorMessage={errors.name && 'Kolom Nama Marketing harus diisi'}
                />
              </form>
            </div>
          </div>
        </div>
      }
      ModalButton={
        <>
          <SubmitButton
            type="submit"
            form="createForm"
            text="Submit"
            textClass="text-white text-xs"
            isLoading={isSubmittingForm}
          />
          <BasicButton text="Cancel" textClass="text-white text-xs mr-2" onClick={closeForm} />
        </>
      }
    />
  );
}

export default forwardRef(MemberCreate);
