import React from 'react';
import {
  ChartSquareBarIcon,
  PresentationChartLineIcon,
  CalendarIcon,
  CashIcon,
  IdentificationIcon,
  ReceiptTaxIcon,
  UserGroupIcon,
  SparklesIcon,
  BriefcaseIcon,
  MicrophoneIcon,
  UserIcon,
  CreditCardIcon,
  LoginIcon,
  OfficeBuildingIcon,
  LockClosedIcon,
} from '@heroicons/react/outline';
import { useSelector } from 'react-redux';
import { useLocation, Link } from 'react-router-dom';

// import UserDropdown from '../../components/Dropdowns/UserDropdown.js';

const sidebarLogo = require('../../assets/img/elgroup-white.png');

export default function BackofficeSidebar() {
  const location = useLocation();

  const { result: authenticatedUser } = useSelector((state) => state.authenticatedUser);

  const [collapseShow, setCollapseShow] = React.useState('hidden');

  const iconClass = 'mr-2 h-5 w-5 opacity-75';
  const menus = [
    {
      name: 'Dashboard',
      childs: [
        {
          roles: [1, 2, 3, 4, 5, 8],
          name: 'Dashboard',
          icon: <ChartSquareBarIcon className={iconClass} />,
          route: '/backoffice/dashboard',
        },
        {
          roles: [1, 2, 3, 4, 5, 8],
          name: 'Club Dashboard',
          icon: <ChartSquareBarIcon className={iconClass} />,
          route: '/backoffice/club-dashboard',
        },
        {
          roles: [1, 2, 3, 4, 8],
          name: 'Performance',
          icon: <PresentationChartLineIcon className={iconClass} />,
          route: '/backoffice/performance',
        },
        {
          roles: [1, 2, 3, 4, 8],
          name: 'Daily Attendance',
          icon: <CalendarIcon className={iconClass} />,
          route: '/backoffice/daily-attendance',
        },
      ],
    },
    {
      name: 'Membership',
      childs: [
        {
          roles: [1, 3, 7],
          name: 'Transaction',
          icon: <CashIcon className={iconClass} />,
          route: '/backoffice/member-report',
        },
        {
          roles: [1, 3, 7],
          name: 'Member',
          icon: <IdentificationIcon className={iconClass} />,
          route: '/backoffice/member',
        },
        {
          roles: [1, 3, 7],
          name: 'Promotion Banner',
          icon: <ReceiptTaxIcon className={iconClass} />,
          route: '/backoffice/member-promotion',
        },
      ],
    },
    {
      name: 'Administration',
      childs: [
        {
          roles: [1, 2, 8],
          name: 'Ladies',
          icon: <UserGroupIcon className={iconClass} />,
          route: '/backoffice/therapist',
        },
        {
          roles: [1, 2, 8],
          name: 'Ladies Grade',
          icon: <SparklesIcon className={iconClass} />,
          route: '/backoffice/grade',
        },
        {
          roles: [1, 2, 8],
          name: 'Marketing / PR',
          icon: <BriefcaseIcon className={iconClass} />,
          route: '/backoffice/marketing',
        },
        {
          roles: [1, 2, 8],
          name: 'Harga LC',
          icon: <MicrophoneIcon className={iconClass} />,
          route: '/backoffice/lc',
        },
        {
          roles: [1, 2, 8],
          name: 'Room',
          icon: <LoginIcon className={iconClass} />,
          route: '/backoffice/room',
        },
        {
          roles: [1, 2, 8],
          name: 'Payment Method',
          icon: <CreditCardIcon className={iconClass} />,
          route: '/backoffice/payment-method',
        },
        {
          roles: [1, 8],
          name: 'User',
          icon: <UserIcon className={iconClass} />,
          route: '/backoffice/user',
        },
        {
          roles: [1, 8],
          name: 'Outlet',
          icon: <OfficeBuildingIcon className={iconClass} />,
          route: '/backoffice/outlet',
        },
      ],
    },
    {
      name: 'Profile Information',
      childs: [
        /* {
          name: 'Profile',
          icon: 'fas fa-user',
          route: '/owner/profile',
        }, */
        {
          roles: [1, 2, 3, 4, 5, 6, 7, 8],
          name: 'Password',
          icon: <LockClosedIcon className={iconClass} />,
          route: '/backoffice/password',
        },
      ],
    },
  ];

  return (
    <>
      <nav className="md:left-0 md:block md:fixed md:top-0 md:bottom-0 md:overflow-y-auto md:flex-row md:flex-no-wrap md:overflow-hidden shadow-xl bg-white flex flex-wrap items-center justify-between relative md:w-64 z-10 py-3 px-6 bg-gray-800">
        <div className="md:flex-col md:items-stretch md:min-h-full md:flex-no-wrap px-0 flex flex-wrap items-center justify-between w-full mx-auto">
          {/* Toggler (Mobile Left Icon) */}
          <button
            className="cursor-pointer text-white md:hidden px-1 py-1 text-xl leading-none bg-transparent rounded border border-solid border-transparent"
            type="button"
            onClick={() => setCollapseShow('bg-white m-2 py-3 px-6')}>
            <i className="fas fa-bars"></i>
          </button>
          {/* Brand */}
          <Link className="md:flex md:mt-4 justify-center" to="/">
            <img src={sidebarLogo} alt="ELGROUP INTERNAL SYSTEM" className="h-12" />
          </Link>
          {/* Profile (Mobile Right Icon) */}
          <ul className="md:hidden items-center flex flex-wrap list-none">
            <li className="inline-block relative">{/* <UserDropdown /> */}</li>
          </ul>

          {/* Collapse */}
          <div
            className={`md:flex md:flex-col md:items-stretch md:opacity-100 md:relative md:mt-4 pb-5 md:shadow-none
              shadow absolute top-0 left-0 right-0 z-40 overflow-y-auto overflow-x-hidden h-auto items-center
              flex-1 rounded ${collapseShow}`}>
            {/* Collapse Header */}
            <div className="md:min-w-full md:hidden block md:pb-4 md:mb-4">
              <div className="flex flex-wrap">
                <div className="w-6/12">
                  <Link
                    className="md:block text-left md:pb-2 text-gray-700 mr-0 inline-block whitespace-nowrap text-sm uppercase font-bold p-4 px-0"
                    to="/">
                    ELGROUP INTERNAL SYSTEM
                  </Link>
                </div>
                <div className="w-6/12 flex justify-end">
                  <button
                    type="button"
                    className="cursor-pointer text-black opacity-50 md:hidden px-3 py-1 text-xl leading-none bg-transparent rounded border border-solid border-transparent"
                    onClick={() => setCollapseShow('hidden')}>
                    <i className="fas fa-times"></i>
                  </button>
                </div>
              </div>
            </div>

            {/* Menus */}
            <div>
              {menus
                .filter(
                  (menu) =>
                    menu.childs.filter(
                      (child) => child.roles.indexOf(authenticatedUser.user.role_id) >= 0,
                    ).length > 0,
                )
                .map((menu, index) => (
                  <div key={menu.name}>
                    {/* Divider */}
                    <hr className="mt-2 md:min-w-full md:opacity-25" />

                    {/* Heading */}
                    <h6
                      className="block pt-4 pb-2 md:min-w-full
                        text-gray-800 md:text-white text-xs uppercase font-bold no-underline">
                      {menu.name}
                    </h6>

                    {/* Navigation */}
                    <ul className="mb-4 md:flex-col md:min-w-full flex flex-col list-none">
                      {menu.childs
                        .filter((child) => child.roles.indexOf(authenticatedUser.user.role_id) >= 0)
                        .map((child) => (
                          <Link key={child.name} to={child.route}>
                            <li
                              className={`flex flex-row px-5 py-3 items-center
                                    rounded-md hover:bg-cyan-500 transition-all
                                    text-xs font-bold uppercase hover:text-white
                                    ${
                                      location.pathname === child.route
                                        ? 'bg-cyan-500 text-white'
                                        : 'text-gray-400'
                                    }`}>
                              {child.icon}
                              {` ${child.name}`}
                            </li>
                          </Link>
                        ))}
                    </ul>
                  </div>
                ))}
            </div>
          </div>
        </div>
      </nav>
    </>
  );
}
