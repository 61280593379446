import React, { forwardRef, useImperativeHandle, useState, useEffect } from 'react';
import { XIcon } from '@heroicons/react/outline';
import { Dialog } from '@headlessui/react';
import Select from 'react-select';
import { useForm, Controller } from 'react-hook-form';
import { useSelector, useDispatch } from 'react-redux';
import moment from 'moment';

import {
  Modal,
  InlineTextInput,
  InlineSelect,
  BasicButton,
  SubmitButton,
  DeleteButton,
} from '../../../../components/Base';
import FilterOutlet from '../../FilterOutlet';
import FilterDate from '../../FilterDate';
import { BOOK_STATUS, BOOK_TIME_INTERVAL } from '../../../../constants/env';

import { submitBook, updateBook, deleteBook } from '../../../../actions/book';

function BookCreateFnb(props, ref) {
  const { register, errors, control, handleSubmit, setValue } = useForm();

  const [isVisible, setIsVisible] = useState(false);
  const [initialBook, setInitialBook] = useState(null);
  const [bookingStatus, setBookingStatus] = useState(0);
  const [bookingSchedules, setBookingSchedules] = useState([]);
  const [isSubmittingForm, setIsSubmittingForm] = useState(false);

  const { filterOutlet, filterDate } = useSelector((state) => state.filter);
  const { result: paymentMethods } = useSelector((state) => state.paymentMethod);
  const dispatch = useDispatch();

  useImperativeHandle(ref, () => ({
    showForm,
    showFormWithInitialData,
  }));

  useEffect(() => {
    mapBookingSchedules();
  }, [filterOutlet, filterDate]);

  useEffect(() => {
    if (initialBook) {
      setTimeout(() => {
        // setValue('book_time', initialBook.book_time);
        // setValue('customer_name', initialBook.customer_name);
        // setValue('status', initialBook.status);
        // setValue('cashier_name', initialBook.cashier_name);
        // setValue('remarks', initialBook.remarks);

        paymentMethods.forEach((fnbPayment, idx) => {
          const payment = initialBook.book_fnb_payments.find(
            (fp) => fp.payment_method_id === fnbPayment.id,
          );
          if (payment) setValue(`fnb_payments[${idx}].price`, payment.price);
          else setValue(`fnb_payments[${idx}].price`, 0);
        });

        setBookingStatus(initialBook.status);
      }, 100);
    }
  }, [initialBook]);

  const showForm = () => setIsVisible(true);
  const closeForm = () => {
    setIsVisible(false);
    setInitialBook(null);
    setIsSubmittingForm(false);
  };

  const showFormWithInitialData = (data) => {
    setInitialBook(data);
    showForm();
  };

  const mapBookingSchedules = () => {
    const startTime = moment(filterDate.startDate);
    const endTime = moment(filterDate.endDate);
    const openDuration = moment.duration(endTime.diff(startTime)).as('minutes');
    const currentTime = moment().format('YYYY-MM-DD HH:mm:ss');

    const schedules = [];
    for (let index = 0; index < openDuration / BOOK_TIME_INTERVAL; index++) {
      const momentDate = moment(filterDate.startDate).add(BOOK_TIME_INTERVAL * index, 'minutes');
      const value = momentDate.format('YYYY-MM-DD HH:mm:ss');
      schedules.push({
        value,
        label: moment(filterDate.startDate)
          .add(BOOK_TIME_INTERVAL * index, 'minutes')
          .format('DD MMM YYYY hh:mm A'),
        isDisabled: value <= currentTime,
      });
    }

    setBookingSchedules(schedules);
  };

  const onSubmitForm = (data) => {
    setIsSubmittingForm(true);

    // Change Data Format
    data.outlet_id = filterOutlet.id;
    data.book_time = data.book_time.value;

    if (initialBook) {
      dispatch(updateBook(initialBook.id, data))
        .then(() => closeForm())
        .catch(() => setIsSubmittingForm(false));
    } else {
      dispatch(submitBook(data))
        .then(() => closeForm())
        .catch(() => setIsSubmittingForm(false));
    }
  };

  const onPressDelete = () => {
    dispatch(deleteBook(initialBook.id))
      .then(() => closeForm())
      .catch(() => setIsSubmittingForm(false));
  };

  return (
    <Modal
      isVisible={isVisible}
      size="large"
      ModalContent={
        <div className="sm:flex sm:items-start">
          <div className="w-full mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
            <div className="flex items-center">
              <div className="flex flex-1">
                <Dialog.Title as="h3" className="text-lg leading-6 font-medium text-gray-900">
                  {initialBook ? 'Ubah Booking FnB' : 'Tambah Booking FnB Baru'}
                </Dialog.Title>
                <div className="ml-3">{initialBook && BOOK_STATUS[initialBook.status]}</div>
              </div>
              <button type="button" className="focus:outline-none" onClick={closeForm}>
                <XIcon className="h-7 w-7 opacity-50" />
              </button>
            </div>

            <div className="mt-8">
              <form id="fnbCreateForm" onSubmit={handleSubmit(onSubmitForm)}>
                <div className="relative w-full mb-5">
                  <div className="grid grid-cols-1 md:grid-cols-3 xl:grid-cols-4 gap-1 md:gap-4 items-center">
                    <div>
                      <label className="block uppercase text-gray-700 text-xs font-bold">
                        Outlet*
                      </label>
                    </div>

                    <div className="col-span-2 xl:col-span-3">
                      <div className="relative flex flex-wrap items-stretch w-full">
                        <FilterOutlet />
                      </div>
                    </div>
                  </div>
                </div>

                <div className="relative w-full mb-5">
                  <div className="grid grid-cols-1 md:grid-cols-3 xl:grid-cols-4 gap-1 md:gap-4 items-center">
                    <div>
                      <label className="block uppercase text-gray-700 text-xs font-bold">
                        Tanggal*
                      </label>
                    </div>

                    <div className="col-span-2 xl:col-span-3">
                      <div className="relative flex flex-wrap items-stretch w-full">
                        <FilterDate />
                      </div>
                    </div>
                  </div>
                </div>

                <div className="relative w-full mb-5">
                  <div className="grid grid-cols-1 md:grid-cols-3 xl:grid-cols-4 gap-1 md:gap-4 items-center">
                    <div>
                      <label className="block uppercase text-gray-700 text-xs font-bold">
                        Jam Booking*
                      </label>
                    </div>

                    <div className="col-span-2 xl:col-span-3">
                      <div className="relative flex flex-wrap items-stretch w-full">
                        <Controller
                          control={control}
                          name="book_time"
                          rules={{ required: true }}
                          defaultValue={
                            initialBook
                              ? bookingSchedules.find((s) => s.value === initialBook.book_time)
                              : bookingSchedules[0]
                          }
                          render={({ onChange, onBlur, value }) => (
                            <Select
                              className="w-full"
                              onChange={onChange}
                              value={value}
                              options={bookingSchedules}
                            />
                          )}
                        />
                      </div>

                      {errors.book_time && (
                        <span className="text-xs text-red-500">Kolom Jam Booking harus diisi</span>
                      )}
                    </div>
                  </div>
                </div>

                <InlineTextInput
                  type="text"
                  title="Nama Customer"
                  inputRef={register()}
                  name="customer_name"
                  defaultValue={initialBook?.customer_name}
                />

                <div className="mt-10">
                  {initialBook && (
                    <InlineSelect
                      title="Status*"
                      inputRef={register({ required: true })}
                      name="status"
                      options={[
                        <option key={0} value={0}>
                          Pending
                        </option>,
                        // <option key={1} value={1}>Service telah Dimulai</option>,
                        <option key={2} value={2}>
                          Service telah Selesai
                        </option>,
                      ]}
                      onChange={(e) => setBookingStatus(Number(e.target.value))}
                      defaultValue={initialBook?.status}
                      errorMessage={errors.status && 'Kolom Status harus dipilih'}
                    />
                  )}
                </div>

                <InlineTextInput
                  type="text"
                  title="Receptionist"
                  inputRef={register()}
                  name="cashier_name"
                  defaultValue={initialBook?.cashier_name}
                  errorMessage={errors.cashier_name && 'Kolom Receptionist harus diisi'}
                />

                {/* <div className="mt-10">
                  <InlineTextInput
                    type="text"
                    title="Nama Kasir"
                    inputRef={register({ required: bookingStatus === 2 })}
                    name="cashier_name"
                    defaultValue={initialBook?.cashier_name}
                    errorMessage={errors.cashier_name && 'Kolom Nama Kasir harus diisi'}
                  />

                  <InlineTextInput
                    type="number"
                    title="Add. Charge Pool"
                    inputRef={register()}
                    name="additional_charge_pool"
                    defaultValue={initialBook ? initialBook.additional_charge_pool : 0}
                  />

                  <InlineSelect
                    title="Metode Pembayaran"
                    inputRef={register({ required: bookingStatus === 2 })}
                    name="payment_method_id"
                    options={paymentMethods.map((paymentMethod) => (
                      <option key={paymentMethod.id} value={paymentMethod.id}>
                        {paymentMethod.name}
                      </option>
                    ))}
                    defaultValue={initialBook?.payment_method_id}
                    errorMessage={
                      errors.payment_method_id && 'Kolom Metode Pembayaran harus dipilih'
                    }
                  />

                  <InlineTextInput
                    type="text"
                    title="Catatan"
                    inputRef={register()}
                    name="remarks"
                    defaultValue={initialBook?.remarks}
                  />
                </div>

                <div className="relative w-full mt-14">
                  <label className="block text-gray-700 mb-5">Metode Pembayaran FnB</label>

                  {paymentMethods.map((paymentMethod, idx) => (
                    <div key={paymentMethod.id}>
                      <input
                        type="hidden"
                        ref={register()}
                        defaultValue={paymentMethod.id}
                        name={`fnb_payments[${idx}].payment_method_id`}
                      />

                      <InlineTextInput
                        type="number"
                        title={paymentMethod.name}
                        inputRef={register()}
                        name={`fnb_payments[${idx}].price`}
                        defaultValue="0"
                      />
                    </div>
                  ))}
                </div> */}
              </form>
            </div>
          </div>
        </div>
      }
      ModalButton={
        <>
          <SubmitButton
            type="submit"
            form="fnbCreateForm"
            text="Submit"
            textClass="text-white text-xs"
            isLoading={isSubmittingForm}
          />
          <BasicButton text="Cancel" textClass="text-white text-xs mr-2" onClick={closeForm} />

          {initialBook && (
            <div className="flex flex-1">
              <DeleteButton
                color="red"
                text="Cancel / Delete"
                textClass="text-white text-xs"
                onClick={onPressDelete}
              />
            </div>
          )}
        </>
      }
    />
  );
}

export default forwardRef(BookCreateFnb);
