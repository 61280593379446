import React, { forwardRef, useImperativeHandle, useState, useEffect } from 'react';
import { XIcon } from '@heroicons/react/outline';
import { Dialog } from '@headlessui/react';
import Select from 'react-select';
import { useForm, Controller } from 'react-hook-form';
import { useSelector, useDispatch } from 'react-redux';
import moment from 'moment';

import {
  Modal,
  InlineTextInput,
  InlineSelect,
  BasicButton,
  SubmitButton,
  DeleteButton,
} from '../../../../components/Base';
import FilterOutlet from '../../FilterOutlet';
import FilterDate from '../../FilterDate';
import { THERAPIST_TYPES, BOOK_STATUS, BOOK_TIME_INTERVAL } from '../../../../constants/env';

import { submitBook, updateBook, deleteBook } from '../../../../actions/book';

function BookCreate(props, ref) {
  const { register, errors, control, handleSubmit, setValue, getValues } = useForm();

  const [isVisible, setIsVisible] = useState(false);
  const [initialBook, setInitialBook] = useState(null);
  const [duration, setDuration] = useState(THERAPIST_TYPES[0].duration);
  const [bookingStatus, setBookingStatus] = useState(0);
  const [bookingSchedules, setBookingSchedules] = useState([]);
  const [unavailableSchedules, setUnavailableSchedules] = useState([]);
  const [isSubmittingForm, setIsSubmittingForm] = useState(false);

  const { filterOutlet, filterDate } = useSelector((state) => state.filter);
  const { result: dailyAttendances } = useSelector((state) => state.dailyAttendance);
  const { result: rooms } = useSelector((state) => state.room);
  const { result: paymentMethods } = useSelector((state) => state.paymentMethod);
  const dispatch = useDispatch();

  useImperativeHandle(ref, () => ({
    showForm,
    showFormWithInitialData,
  }));

  useEffect(() => {
    mapBookingSchedules();
  }, [filterOutlet, filterDate, dailyAttendances, unavailableSchedules]);

  useEffect(() => {
    const dailyAttendance = getValues('daily_attendance_id');
    if (dailyAttendance) mapBookingSchedules(dailyAttendance.value);
  }, [duration]);

  useEffect(() => {
    if (initialBook) {
      setTimeout(() => {
        paymentMethods.forEach((fnbPayment, idx) => {
          const payment = initialBook.book_fnb_payments.find(
            (fp) => fp.payment_method_id === fnbPayment.id,
          );
          if (payment) setValue(`fnb_payments[${idx}].price`, payment.price);
          else setValue(`fnb_payments[${idx}].price`, 0);
        });

        setDuration(initialBook.duration);
        setBookingStatus(initialBook.status);
        mapUnavailableSchedules(initialBook.daily_attendance_id);
      }, 100);
    } else {
      // Set Data to default Empty Form
      setDuration(THERAPIST_TYPES[0].duration);
      setBookingStatus(0);
      setUnavailableSchedules([]);
    }
  }, [initialBook]);

  const showForm = () => setIsVisible(true);
  const closeForm = () => {
    setIsVisible(false);
    setInitialBook(null);
    setIsSubmittingForm(false);
  };

  const showFormWithInitialData = (data) => {
    setInitialBook(data);
    showForm();
  };

  const mapBookingSchedules = () => {
    const startTime = moment(filterDate.startDate);
    const endTime = moment(filterDate.endDate);
    const openDuration = moment.duration(endTime.diff(startTime)).as('minutes');
    const currentTime = moment().format('YYYY-MM-DD HH:mm:ss');

    const schedules = [];
    for (let index = 0; index < openDuration / BOOK_TIME_INTERVAL; index++) {
      const momentDate = moment(filterDate.startDate).add(BOOK_TIME_INTERVAL * index, 'minutes');
      const value = momentDate.format('YYYY-MM-DD HH:mm:ss');
      schedules.push({
        value,
        label: moment(filterDate.startDate)
          .add(BOOK_TIME_INTERVAL * index, 'minutes')
          .format('DD MMM YYYY hh:mm A'),
        isDisabled: unavailableSchedules.indexOf(value) >= 0, // || value <= currentTime,
      });
    }

    setBookingSchedules(schedules);
  };

  const mapUnavailableSchedules = (dailyAttendanceId) => {
    const selectedAttendances = dailyAttendances.find((a) => a.id === Number(dailyAttendanceId));
    const unavailableDates = [];
    selectedAttendances.books
      .filter((book) => book.status !== 2)
      .filter((book) => (initialBook ? initialBook.id !== book.id : true))
      .forEach((book) => {
        for (let index = 0; index < Number(book.duration) / BOOK_TIME_INTERVAL; index++) {
          unavailableDates.push(
            moment(book.book_time)
              .add(BOOK_TIME_INTERVAL * index, 'minutes')
              .format('YYYY-MM-DD HH:mm:ss'),
          );
        }
        for (let index = 1; index < Number(duration) / BOOK_TIME_INTERVAL; index++) {
          unavailableDates.push(
            moment(book.book_time)
              .subtract(BOOK_TIME_INTERVAL * index, 'minutes')
              .format('YYYY-MM-DD HH:mm:ss'),
          );
        }
      });
    setUnavailableSchedules(unavailableDates);
  };

  const onSubmitForm = (data) => {
    setIsSubmittingForm(true);

    if (initialBook) {
      data.outlet_id = initialBook.outlet_id;
      data.duration = initialBook.duration;
      data.daily_attendance_id = initialBook.daily_attendance_id;
      data.book_time = data.book_time.value;
      if (data.room_id) data.room_id = data.room_id.value;

      dispatch(updateBook(initialBook.id, data))
        .then(() => closeForm())
        .catch(() => setIsSubmittingForm(false));
    } else {
      // Change Data Format
      data.outlet_id = filterOutlet.id;
      data.duration = duration;
      data.daily_attendance_id = data.daily_attendance_id.value;
      data.book_time = data.book_time.value;
      if (data.room_id) data.room_id = data.room_id.value;

      dispatch(submitBook(data))
        .then(() => closeForm())
        .catch(() => setIsSubmittingForm(false));
    }
  };

  const onPressDelete = () => {
    dispatch(deleteBook(initialBook.id))
      .then(() => closeForm())
      .catch(() => setIsSubmittingForm(false));
  };

  return (
    <Modal
      isVisible={isVisible}
      size="large"
      ModalContent={
        <div className="sm:flex sm:items-start">
          <div className="w-full mt-3 text-center sm:mt-0 sm:mx-2 sm:text-left">
            <div className="flex items-center">
              <div className="flex flex-1">
                <Dialog.Title as="h3" className="text-lg leading-6 font-medium text-gray-900">
                  {initialBook ? 'Ubah Booking' : 'Tambah Booking Baru'}
                </Dialog.Title>
                <div className="ml-3">{initialBook && BOOK_STATUS[initialBook.status]}</div>
              </div>
              <button type="button" className="focus:outline-none" onClick={closeForm}>
                <XIcon className="h-7 w-7 opacity-50" />
              </button>
            </div>

            <div className="mt-8">
              <form id="createForm" onSubmit={handleSubmit(onSubmitForm)}>
                <div className="relative w-full mb-5">
                  <div className="grid grid-cols-1 md:grid-cols-3 xl:grid-cols-4 gap-1 md:gap-4 items-center">
                    <div>
                      <label className="block uppercase text-gray-700 text-xs font-bold">
                        Outlet*
                      </label>
                    </div>

                    <div className="col-span-2 xl:col-span-3">
                      <div className="relative flex flex-wrap items-stretch w-full">
                        {initialBook ? (
                          <input
                            className="px-3 py-3 w-full text-gray-700 text-sm bg-gray-100 rounded shadow"
                            disabled
                            value={initialBook.outlet_name}
                          />
                        ) : (
                          <FilterOutlet />
                        )}
                      </div>
                    </div>
                  </div>
                </div>

                <div className="relative w-full mb-5">
                  <div className="grid grid-cols-1 md:grid-cols-3 xl:grid-cols-4 gap-1 md:gap-4 items-center">
                    <div>
                      <label className="block uppercase text-gray-700 text-xs font-bold">
                        Tanggal*
                      </label>
                    </div>

                    <div className="col-span-2 xl:col-span-3">
                      <div className="relative flex flex-wrap items-stretch w-full">
                        {initialBook ? (
                          <input
                            className="px-3 py-3 w-full text-gray-700 text-sm bg-gray-100 rounded shadow"
                            disabled
                            value={moment(initialBook.book_time).format('YYYY-MM-DD')}
                          />
                        ) : (
                          <FilterDate />
                        )}
                      </div>
                    </div>
                  </div>
                </div>

                <div className="relative w-full mb-5">
                  <div className="grid grid-cols-1 md:grid-cols-3 xl:grid-cols-4 gap-1 md:gap-4 items-center">
                    <label className="block uppercase text-gray-700 text-xs font-bold">
                      Ladies*
                    </label>
                    <div className="col-span-2 xl:col-span-3">
                      {initialBook ? (
                        <input
                          className="px-3 py-3 w-full text-gray-700 text-sm bg-gray-100 rounded shadow"
                          disabled
                          value={initialBook.therapist_name}
                        />
                      ) : (
                        <div className="relative flex flex-wrap items-stretch w-full">
                          <Controller
                            control={control}
                            name="daily_attendance_id"
                            rules={{ required: true }}
                            render={({ onChange, onBlur, value }) => (
                              <Select
                                className="w-full"
                                onChange={(props) => {
                                  onChange(props);
                                  mapUnavailableSchedules(props.value);
                                }}
                                value={value}
                                options={dailyAttendances.map((d) => ({
                                  value: d.id,
                                  label: d.therapist.name,
                                }))}
                              />
                            )}
                          />
                        </div>
                      )}
                      {errors.daily_attendance_id && (
                        <span className="text-xs text-red-500">Kolom Ladies harus dipilih</span>
                      )}
                    </div>
                  </div>
                </div>

                <div className="relative w-full mb-5">
                  <div className="grid grid-cols-1 md:grid-cols-3 xl:grid-cols-4 gap-1 md:gap-4">
                    <label className="mt-2 block uppercase text-gray-700 text-xs font-bold">
                      Tipe Ladies*
                    </label>
                    <div className="col-span-2 xl:col-span-3">
                      {initialBook ? (
                        <input
                          className="px-3 py-3 w-full text-gray-700 text-sm bg-gray-100 rounded shadow"
                          disabled
                          value={initialBook.therapist_type}
                        />
                      ) : (
                        THERAPIST_TYPES.map((type) => (
                          <label key={type.name} className="flex items-center">
                            <input
                              ref={register({ required: true })}
                              type="radio"
                              className="form-radio"
                              name="therapist_type"
                              value={type.name}
                              // defaultChecked={initialBook && initialBook.therapist_type === type.name}
                              onChange={() => setDuration(type.duration)}
                            />
                            <span className="ml-2">{`${type.name} (${type.duration} minutes)`}</span>
                          </label>
                        ))
                      )}

                      {errors.therapist_type && (
                        <span className="text-xs text-red-500">
                          Kolom Tipe Ladies harus dipilih
                        </span>
                      )}
                    </div>
                  </div>
                </div>

                <div className="relative w-full mb-5">
                  <div className="grid grid-cols-1 md:grid-cols-3 xl:grid-cols-4 gap-1 md:gap-4 items-center">
                    <label className="block uppercase text-gray-700 text-xs font-bold">
                      Jam Booking*
                    </label>
                    <div className="col-span-2 xl:col-span-3">
                      <div className="relative flex flex-wrap items-stretch w-full">
                        <Controller
                          control={control}
                          name="book_time"
                          rules={{ required: true }}
                          defaultValue={
                            initialBook
                              ? bookingSchedules.find((s) => s.value === initialBook.book_time)
                              : bookingSchedules[0]
                          }
                          render={({ onChange, onBlur, value }) => (
                            <Select
                              className="w-full"
                              onChange={onChange}
                              value={value}
                              options={bookingSchedules}
                            />
                          )}
                        />
                      </div>
                      {errors.book_time && (
                        <span className="text-xs text-red-500">Kolom Jam Booking harus diisi</span>
                      )}
                    </div>
                  </div>
                </div>

                <InlineTextInput
                  editable={initialBook ? false : true}
                  type="text"
                  title="Nama GRO*"
                  inputRef={register({ required: true })}
                  name="gro_name"
                  defaultValue={initialBook?.gro_name}
                  errorMessage={errors.gro_name && 'Kolom Nama GRO harus diisi'}
                />

                <InlineTextInput
                  editable={initialBook ? false : true}
                  type="text"
                  title="Nama Customer*"
                  inputRef={register({ required: true })}
                  name="customer_name"
                  defaultValue={initialBook?.customer_name}
                  errorMessage={errors.customer_name && 'Kolom Nama Customer harus diisi'}
                />

                <div className="mt-10">
                  {initialBook && (
                    <InlineSelect
                      title="Status*"
                      inputRef={register({ required: true })}
                      name="status"
                      options={[
                        <option key={0} value={0}>
                          Pending
                        </option>,
                        <option key={1} value={1}>
                          Service telah Dimulai
                        </option>,
                        <option key={2} value={2}>
                          Service telah Selesai
                        </option>,
                      ]}
                      onChange={(e) => setBookingStatus(Number(e.target.value))}
                      defaultValue={initialBook?.status}
                      errorMessage={errors.status && 'Kolom Status harus dipilih'}
                    />
                  )}

                  <div className="relative w-full mb-5">
                    <div className="grid grid-cols-1 md:grid-cols-3 xl:grid-cols-4 gap-1 md:gap-4 items-center">
                      <div>
                        <label className="block uppercase text-gray-700 text-xs font-bold">
                          Room No
                        </label>
                      </div>

                      <div className="col-span-2 xl:col-span-3">
                        <div className="relative flex flex-wrap items-stretch w-full">
                          <Controller
                            control={control}
                            name="room_id"
                            defaultValue={
                              initialBook && initialBook.room_id
                                ? { value: initialBook.room_id, label: initialBook.room_name }
                                : null
                            }
                            render={({ onChange, onBlur, value }) => (
                              <Select
                                className="w-full"
                                onChange={onChange}
                                value={value}
                                options={rooms.map((room) => ({
                                  value: room.id,
                                  label: room.name,
                                }))}
                              />
                            )}
                          />
                        </div>

                        {errors.room_id && (
                          <span className="text-xs text-red-500">Kolom Room No harus dipilih</span>
                        )}
                      </div>
                    </div>
                  </div>
                </div>

                <InlineTextInput
                  type="text"
                  title="Receptionist"
                  inputRef={register()}
                  name="cashier_name"
                  defaultValue={initialBook?.cashier_name}
                  errorMessage={errors.cashier_name && 'Kolom Receptionist harus diisi'}
                />

                {/* <div className="mt-10">
                  <InlineTextInput
                    type="text"
                    title="Nama Kasir"
                    inputRef={register({ required: bookingStatus === 2 })}
                    name="cashier_name"
                    defaultValue={initialBook?.cashier_name}
                    errorMessage={errors.cashier_name && 'Kolom Nama Kasir harus diisi'}
                  />

                  <InlineTextInput
                    type="number"
                    title="Discount"
                    inputRef={register()}
                    name="discount"
                    defaultValue={initialBook ? initialBook.discount : 0}
                  />

                  <InlineTextInput
                    type="number"
                    title="Tips"
                    inputRef={register()}
                    name="tip"
                    defaultValue={initialBook ? initialBook.tip : 0}
                  />

                  <InlineTextInput
                    type="number"
                    title="Add. Charge"
                    inputRef={register()}
                    name="additional_charge"
                    defaultValue={initialBook ? initialBook.additional_charge : 0}
                  />

                  <InlineTextInput
                    type="number"
                    title="Add. Charge Pool"
                    inputRef={register()}
                    name="additional_charge_pool"
                    defaultValue={initialBook ? initialBook.additional_charge_pool : 0}
                  />

                  <InlineSelect
                    title="Metode Pembayaran"
                    inputRef={register({ required: bookingStatus === 2 })}
                    name="payment_method_id"
                    options={paymentMethods.map((paymentMethod) => (
                      <option key={paymentMethod.id} value={paymentMethod.id}>
                        {paymentMethod.name}
                      </option>
                    ))}
                    defaultValue={initialBook?.payment_method_id}
                    errorMessage={
                      errors.payment_method_id && 'Kolom Metode Pembayaran harus dipilih'
                    }
                  />

                  <InlineTextInput
                    type="text"
                    title="Catatan"
                    inputRef={register()}
                    name="remarks"
                    defaultValue={initialBook?.remarks}
                  />
                </div>

                <div className="relative w-full mt-14">
                  <label className="block text-gray-700 mb-5">Metode Pembayaran FnB</label>

                  {paymentMethods.map((paymentMethod, idx) => (
                    <div key={paymentMethod.id}>
                      <input
                        type="hidden"
                        ref={register()}
                        defaultValue={paymentMethod.id}
                        name={`fnb_payments[${idx}].payment_method_id`}
                      />

                      <InlineTextInput
                        type="number"
                        title={paymentMethod.name}
                        inputRef={register()}
                        name={`fnb_payments[${idx}].price`}
                        defaultValue="0"
                      />
                    </div>
                  ))}
                </div> */}

                {initialBook && (
                  <div className="mt-4 text-right text-sm italic">
                    Booking di-submit pada{' '}
                    <strong>{moment(initialBook.created_at).format('DD MMM YYYY HH:mm:ss')}</strong>
                  </div>
                )}
              </form>
            </div>
          </div>
        </div>
      }
      ModalButton={
        <>
          <SubmitButton
            type="submit"
            form="createForm"
            text="Submit"
            textClass="text-white text-xs"
            isLoading={isSubmittingForm}
          />
          <BasicButton text="Cancel" textClass="text-white text-xs mr-2" onClick={closeForm} />

          {initialBook && (
            <div className="flex flex-1">
              <DeleteButton
                color="red"
                text="Cancel / Delete"
                textClass="text-white text-xs"
                onClick={onPressDelete}
              />
            </div>
          )}
        </>
      }
    />
  );
}

export default forwardRef(BookCreate);
