import {
  FETCH_MEMBER_PROMOTION,
  SUBMIT_MEMBER_PROMOTION,
  DELETE_MEMBER_PROMOTION,
  REFRESH_MEMBER_PROMOTION_DATATABLES,
  RESET_STORE,
} from '../actions/_types';

const initialState = {
  result: [],
  submitResult: {},
  deleteResult: {},

  refresh: false,
};

export default function memberPromotion(state = initialState, action) {
  switch (action.type) {
    case FETCH_MEMBER_PROMOTION:
      return {
        ...state,
        result: action.result,
      };

    case SUBMIT_MEMBER_PROMOTION:
      return {
        ...state,
        submitResult: action.result,
      };

    case DELETE_MEMBER_PROMOTION:
      return {
        ...state,
        deleteResult: action.result,
      };

    case REFRESH_MEMBER_PROMOTION_DATATABLES:
      return {
        ...state,
        refresh: !state.refresh,
      };

    case RESET_STORE:
      return { ...initialState };

    default:
      return state;
  }
}
