import Swal from 'sweetalert2';
import {
  FETCH_MEMBER_PROMOTION,
  SUBMIT_MEMBER_PROMOTION,
  DELETE_MEMBER_PROMOTION,
  REFRESH_MEMBER_PROMOTION_DATATABLES,
} from './_types';
import { API_URL, API_RESPONSE_STATUS, API_RESPONSE_MESSAGE, API_RESPONSE } from '../constants/env';
import { authApiHeader, authApiHeaderMultipart } from '../helpers/apiHeader';

export const fetchMemberPromotion = () => {
  return (dispatch) => {
    return fetch(`${API_URL}/member-promotion`, {
      method: 'GET',
      headers: authApiHeader(),
    })
      .then((response) => response.json())
      .then((response) => {
        switch (response[API_RESPONSE_STATUS]) {
          case API_RESPONSE.SUCCESS:
            dispatch({ type: FETCH_MEMBER_PROMOTION, result: response.data });
            break;

          case API_RESPONSE.FAILED:
          default:
            dispatch({ type: FETCH_MEMBER_PROMOTION, result: [] });
            Swal.fire('Fetch Promotion Failed', response[API_RESPONSE_MESSAGE], 'error');
        }
      });
  };
};

export const emptyFetchedMemberPromotion = () => {
  return (dispatch) => {
    dispatch({ type: FETCH_MEMBER_PROMOTION, result: [] });
  };
};

export const refreshMemberPromotionDatatables = () => {
  return (dispatch) => {
    dispatch({ type: REFRESH_MEMBER_PROMOTION_DATATABLES });
  };
};

/**
 * @param data      FormData : banner, title, content, status
 */
export const submitMemberPromotion = (formData) => {
  return (dispatch) => {
    return fetch(`${API_URL}/member-promotion`, {
      method: 'POST',
      body: formData,
      headers: authApiHeaderMultipart(),
    })
      .then((response) => response.json())
      .then((response) => {
        switch (response[API_RESPONSE_STATUS]) {
          case API_RESPONSE.SUCCESS:
            Swal.fire('Submit Success', response[API_RESPONSE_MESSAGE], 'success');
            dispatch({ type: SUBMIT_MEMBER_PROMOTION, result: response });
            break;

          case API_RESPONSE.FAILED:
          default:
            Swal.fire('Submit Failed', response[API_RESPONSE_MESSAGE], 'error');
            return Promise.reject(response[API_RESPONSE_MESSAGE]);
        }
      })
      .then(() => dispatch(refreshMemberPromotionDatatables()));
  };
};

/**
 * @param id
 * @param data      FormData : banner, title, content, status
 */
export const updateMemberPromotion = (id, formData) => {
  formData.append('_method', 'PUT');
  return (dispatch) => {
    return fetch(`${API_URL}/member-promotion/${id}`, {
      method: 'POST',
      body: formData,
      headers: authApiHeaderMultipart(),
    })
      .then((response) => response.json())
      .then((response) => {
        switch (response[API_RESPONSE_STATUS]) {
          case API_RESPONSE.SUCCESS:
            Swal.fire('Submit Success', response[API_RESPONSE_MESSAGE], 'success');
            dispatch({ type: SUBMIT_MEMBER_PROMOTION, result: response });
            break;

          case API_RESPONSE.FAILED:
          default:
            Swal.fire('Submit Failed', response[API_RESPONSE_MESSAGE], 'error');
            return Promise.reject(response[API_RESPONSE_MESSAGE]);
        }
      })
      .then(() => dispatch(refreshMemberPromotionDatatables()));
  };
};

/**
 * @param id
 */
export const deleteMemberPromotion = (id) => {
  return (dispatch) => {
    return fetch(`${API_URL}/member-promotion/${id}`, {
      method: 'DELETE',
      headers: authApiHeader(),
    })
      .then((response) => response.json())
      .then((response) => {
        switch (response[API_RESPONSE_STATUS]) {
          case API_RESPONSE.SUCCESS:
            Swal.fire('Delete Success', response[API_RESPONSE_MESSAGE], 'success');
            dispatch({ type: DELETE_MEMBER_PROMOTION, result: response });
            break;

          case API_RESPONSE.FAILED:
          default:
            Swal.fire('Delete Failed', response[API_RESPONSE_MESSAGE], 'error');
            return Promise.reject(response[API_RESPONSE_MESSAGE]);
        }
      })
      .then(() => dispatch(refreshMemberPromotionDatatables()));
  };
};
