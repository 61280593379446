import React, { useRef, useEffect } from 'react';
import ReactDOM from 'react-dom';
import { useSelector, useDispatch } from 'react-redux';

import $ from 'jquery';

import MemberPromotionCreate from './Create';
import { DeleteButton, SubmitButton } from '../../../components/Base';
import { API_URL, DATATABLES_DEFAULT_CONFIG } from '../../../constants/env';
import { deleteMemberPromotion } from '../../../actions/memberPromotion';

export default function MemberPromotion() {
  const memberPromotionFormRef = useRef();
  const { result: authenticatedUser } = useSelector((state) => state.authenticatedUser);
  const { refresh } = useSelector((state) => state.memberPromotion);

  const dispatch = useDispatch();

  useEffect(() => {
    initializeDataTables();
  }, [refresh]);

  const initializeDataTables = () => {
    if ($.fn.DataTable.isDataTable('#memberPromotionDataTable')) {
      $('#memberPromotionDataTable').DataTable().destroy();
    }
    $('#memberPromotionDataTable').DataTable({
      ...DATATABLES_DEFAULT_CONFIG,
      ajax: {
        url: `${API_URL}/member-promotion?source=datatables`,
        dataSrc: 'data',
        beforeSend: (xhr) =>
          xhr.setRequestHeader('Authorization', `Bearer ${authenticatedUser.token}`),
      },
      columns: [
        { data: 'title', title: 'Judul Promosi' },
        {
          data: 'banner_url',
          title: 'Banner',
          searchable: false,
          sortable: false,
          createdCell: (cell, cellData, rowData) =>
            ReactDOM.render(<img src={cellData} className="w-40" alt={rowData.title} />, cell),
        },
        {
          data: 'status',
          title: 'Status',
          render: (data) =>
            Number(data) === 1
              ? '<strong class="text-green-400">Aktif</strong>'
              : '<span class="text-red-400">Tidak Aktif</span>',
        },
        {
          data: 'id',
          width: 140,
          searchable: false,
          sortable: false,
          createdCell: (cell, cellData, rowData) =>
            ReactDOM.render(
              <div>
                <SubmitButton
                  color="blue"
                  textClass="text-white text-xs"
                  paddingClass="px-3 py-2 mr-1"
                  text="Edit"
                  onClick={() => onPressEdit(rowData)}
                />
                <DeleteButton
                  color="red"
                  textClass="text-white text-xs"
                  paddingClass="px-3 py-2"
                  onClick={() => onPressDelete(rowData)}
                />
              </div>,
              cell,
            ),
        },
      ],
    });
  };

  const onPressCreate = () => memberPromotionFormRef.current.showForm();
  const onPressEdit = (data) => memberPromotionFormRef.current.showFormWithInitialData(data);
  const onPressDelete = (data) => dispatch(deleteMemberPromotion(data.id));

  return (
    <>
      <div className="relative flex flex-col min-w-0 w-full h-full bg-white mb-8 shadow-lg rounded-xl">
        {/* Header */}
        <div className="flex flex-wrap items-center py-3 border-b">
          <div className="px-4 flex-1">
            <h3 className="font-semibold text-gray-800 pl-2">Member Promotion Banner</h3>
          </div>
          <div className="px-4">
            <SubmitButton
              textClass="text-white text-xs"
              text="Tambah Banner"
              onClick={onPressCreate}
            />
            <MemberPromotionCreate ref={memberPromotionFormRef} />
          </div>
        </div>

        {/* Table Content */}
        <div className="p-6 w-full block">
          <table id="memberPromotionDataTable" className="w-full border-collapse"></table>
        </div>
      </div>
    </>
  );
}
