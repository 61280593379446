import React, { forwardRef, useImperativeHandle, useState, useEffect } from 'react';
import { Dialog } from '@headlessui/react';
import { XIcon } from '@heroicons/react/outline';
import { useSelector } from 'react-redux';
import moment from 'moment';
import $ from 'jquery';

import { Modal } from '../../../../components/Base';
import { API_URL, DATATABLES_DEFAULT_CONFIG } from '../../../../constants/env';
import Utils from '../../../../helpers/utils';

function MemberTransaction(props, ref) {
  const [isVisible, setIsVisible] = useState(false);
  const [initialMember, setInitialMember] = useState(null);

  const { result: authenticatedUser } = useSelector((state) => state.authenticatedUser);

  useEffect(() => {
    if (initialMember) setTimeout(initializeDataTables, 100);
  }, [initialMember]);

  //

  useImperativeHandle(ref, () => ({
    showForm,
  }));

  const showForm = (data) => {
    setIsVisible(true);
    setInitialMember(data);
  };
  const closeForm = () => {
    setIsVisible(false);
    setInitialMember(null);
  };

  //

  const initializeDataTables = () => {
    if ($.fn.DataTable.isDataTable('#memberTransactionDataTable')) {
      $('#memberTransactionDataTable').DataTable().destroy();
    }
    $('#memberTransactionDataTable').DataTable({
      ...DATATABLES_DEFAULT_CONFIG,
      dom: `<''
        <'dt-table mt-3'
          <''tr>
        >
        <'sm:flex mt-3 justify-center items-center'
          <'sm:flex-1 text-center sm:text-left'l>
          <'sm:flex-1 text-center sm:text-right'p>
        >
      >`,
      ajax: {
        url: `${API_URL}/member-transaction?source=datatables&m=${initialMember.id}&s=1997-01-01`,
        dataSrc: 'data',
        beforeSend: (xhr) =>
          xhr.setRequestHeader('Authorization', `Bearer ${authenticatedUser.token}`),
      },
      columns: [
        { data: 'time', title: 'Time', render: (data) => moment(data).format('DD MMM YYYY HH:mm') },
        { data: 'outlet_name', title: 'Outlet' },
        {
          data: 'debit',
          title: 'Type',
          render: (data) => (Number(data) > 0 ? 'TOPUP' : 'TRANSACTION'),
        },
        { data: 'description', title: 'Deskripsi' },
        { data: 'debit', title: 'Debit', render: (data) => Utils.numberFormat(data) },
        { data: 'credit', title: 'Credit', render: (data) => Utils.numberFormat(data) },
      ],
      columnDefs: [{ className: 'dt-head-center dt-body-right', targets: [4, 5] }],
    });
  };

  return (
    <Modal
      isVisible={isVisible}
      size="xlarge"
      ModalContent={
        <div className="sm:flex sm:items-start">
          <div className="mt-3 sm:mt-0 w-full text-center sm:text-left">
            <div className="relative">
              <XIcon
                className="h-7 w-7 absolute top-0 right-0 cursor-pointer"
                onClick={closeForm}
              />
              <Dialog.Title as="h3" className="text-lg leading-6 font-medium text-gray-900">
                Transaction Log
              </Dialog.Title>
              <Dialog.Title as="h3" className="text-lg leading-6 font-medium text-gray-900">
                {initialMember?.name} ({initialMember?.card_no})
              </Dialog.Title>
            </div>
            <div className="mt-5 w-full">
              <table id="memberTransactionDataTable" className="w-full border-collapse"></table>
            </div>
          </div>
        </div>
      }
      /* ModalButton={
        <>
          <BasicButton text="Close" textClass="text-white text-xs mr-2" onClick={closeForm} />
        </>
      } */
    />
  );
}

export default forwardRef(MemberTransaction);
