import React, { forwardRef, useImperativeHandle, useState } from 'react';
import { Dialog } from '@headlessui/react';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';

import {
  Modal,
  InlineTextInput,
  InlineTextArea,
  InlineSelect,
  BasicButton,
  SubmitButton,
} from '../../../../components/Base';
import { submitMemberPromotion, updateMemberPromotion } from '../../../../actions/memberPromotion';

function MemberPromotionCreate(props, ref) {
  const { register, errors, handleSubmit, setError, clearErrors } = useForm();

  const [isVisible, setIsVisible] = useState(false);
  const [initialMemberPromotion, setInitialMemberPromotion] = useState(null);
  const [banner, setBanner] = useState('');
  const [isSubmittingForm, setIsSubmittingForm] = useState(false);

  const dispatch = useDispatch();

  //

  useImperativeHandle(ref, () => ({
    showForm,
    showFormWithInitialData,
  }));

  const showForm = () => setIsVisible(true);
  const closeForm = () => {
    setIsVisible(false);
    setInitialMemberPromotion(null);
    setIsSubmittingForm(false);
  };

  const showFormWithInitialData = (data) => {
    setInitialMemberPromotion(data);
    showForm();
  };

  //

  const onBannerChange = (e) => {
    if (e.target.files && e.target.files[0]) {
      const uploadedFile = e.target.files[0];

      if (uploadedFile.type.indexOf('image') === -1) {
        setError('banner', {
          type: 'manual',
          message: 'Banner Image must be an image (jpg, png, gif, svg, or webp)',
        });
        return;
      }

      if (uploadedFile.size > 5000000) {
        setError('banner', {
          type: 'manual',
          message: "Banner Image size can't be larger than 5 MB",
        });
        return;
      }

      clearErrors('banner');
      setBanner(URL.createObjectURL(e.target.files[0]));
    }
  };

  const onSubmitForm = (data) => {
    setIsSubmittingForm(true);

    const formData = new FormData();
    if (data.banner.length > 0) formData.append('banner', data.banner[0]);
    formData.append('title', data.title);
    formData.append('content', data.content);
    formData.append('status', data.status);

    if (initialMemberPromotion) {
      dispatch(updateMemberPromotion(initialMemberPromotion.id, formData))
        .then(() => closeForm())
        .catch(() => setIsSubmittingForm(false));
    } else {
      dispatch(submitMemberPromotion(formData))
        .then(() => closeForm())
        .catch(() => setIsSubmittingForm(false));
    }
  };

  return (
    <Modal
      isVisible={isVisible}
      ModalContent={
        <div className="sm:flex sm:items-start">
          <div className="mt-3 sm:mt-0 w-full text-center sm:text-left">
            <Dialog.Title as="h3" className="text-lg leading-6 font-medium text-gray-900">
              {initialMemberPromotion ? 'Ubah Promotion Banner' : 'Tambah Promotion Banner'}
            </Dialog.Title>
            <div className="mt-5">
              <form id="createForm" onSubmit={handleSubmit(onSubmitForm)}>
                {initialMemberPromotion && banner === '' && (
                  <img src={initialMemberPromotion.banner_url} alt="" className="mb-2" />
                )}
                <img src={banner} alt="" className="mb-2" />

                <InlineTextInput
                  type="file"
                  accept="image/*"
                  title="Banner Image"
                  inputRef={register({ required: initialMemberPromotion ? false : true })}
                  name="banner"
                  onChange={onBannerChange}
                  errorMessage={
                    errors.banner &&
                    (errors.banner.type === 'manual'
                      ? errors.banner.message
                      : 'Kolom Banner Image harus diisi')
                  }
                />
                <div className="-mt-3 mb-4 text-right text-xs">
                  Banner Image size should be 1600x800, Max 5 MB &nbsp;
                </div>

                <InlineTextInput
                  type="text"
                  title="Judul Promosi"
                  inputRef={register({ required: true })}
                  name="title"
                  defaultValue={initialMemberPromotion?.title}
                  errorMessage={errors.title && 'Kolom Judul Promosi harus diisi'}
                />

                <InlineTextArea
                  title="Content"
                  inputRef={register({ required: true })}
                  name="content"
                  defaultValue={initialMemberPromotion?.content}
                  errorMessage={errors.content && 'Kolom Content harus diisi'}
                />

                <InlineSelect
                  inputWidth="w-1/2"
                  title="Status"
                  inputRef={register({ required: true })}
                  name="status"
                  options={[
                    <option value="1">Aktif</option>,
                    <option value="0">Tidak Aktif</option>,
                  ]}
                  defaultValue={
                    initialMemberPromotion?.status ? initialMemberPromotion.status : '1'
                  }
                  errorMessage={errors.status && 'Kolom Status harus dipilih'}
                />
              </form>
            </div>
          </div>
        </div>
      }
      ModalButton={
        <>
          <SubmitButton
            type="submit"
            form="createForm"
            text="Submit"
            textClass="text-white text-xs"
            isLoading={isSubmittingForm}
          />
          <BasicButton text="Cancel" textClass="text-white text-xs mr-2" onClick={closeForm} />
        </>
      }
    />
  );
}

export default forwardRef(MemberPromotionCreate);
